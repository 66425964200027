<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="设备名称" prop="deviceName">
                <a-input v-model="queryParam.deviceName" placeholder="请输入设备名称" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="设备密钥" prop="deviceSecret">
                <a-input v-model="queryParam.deviceSecret" placeholder="请输入设备密钥" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="设备ID" prop="iotId">
                  <a-input
                    v-model="queryParam.iotId"
                    placeholder="请输入设备ID，物联网平台为该设备颁发的唯一标识符"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="申请批次ID" prop="applyId">
                  <a-input v-model="queryParam.applyId" placeholder="请输入申请批次ID" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="注册状态" prop="registerStatus">
                  <a-select
                    placeholder="请选择在阿里云上设备的注册状态"
                    v-model="queryParam.registerStatus"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="item in registerStatusOptions" :key="item.value" :value="item.value">{{
                      item.label
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="gmsl摄像头类型定义" prop="gmslCameraTypes">
                  <a-input v-model="queryParam.gmslCameraTypes" placeholder="请输入gmsl摄像头类型定义" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="内部车辆ID" prop="vehicleId">
                  <a-input v-model="queryParam.vehicleId" placeholder="请输入内部车辆ID" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="启用状态" prop="status">
                  <a-select placeholder="请选择启用状态" v-model="queryParam.status" style="width: 100%" allow-clear>
                    <a-select-option v-for="item in statusOptions" :key="item.value" :value="item.value">{{
                      item.label
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="绑定状态" prop="bindStatus">
                  <a-select
                    placeholder="请选择绑定状态"
                    v-model="queryParam.bindStatus"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="item in bindStatusOptions" :key="item.value" :value="item.value">{{
                      item.label
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:cameraDevice:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button type="primary" style="margin-bottom: 30px" :loading="syncLoading" @click="syncRegisterDevice">
            <a-icon type="redo" />同步数据
          </a-button>
          <!-- <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['tenant:iot:cameraDevice:edit']"
        >
          <a-icon type="edit" />修改
        </a-button>
        <a-button
          type="danger"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['tenant:iot:cameraDevice:remove']"
        >
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:cameraDevice:export']">
          <a-icon type="download" />导出
        </a-button> -->
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-if="record.bindStatus === 1 && !record.vehicleId" />
          <a @click="webReturn(record)" v-if="record.bindStatus === 1 && !record.vehicleId">
            <a-icon type="edit" />归还
          </a>
          <a @click="vehicleReturn(record)" v-if="record.bindStatus === 1 && record.vehicleId">
            <a-icon type="edit" />归还
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {
  listCameraDevice,
  delCameraDevice,
  webReturnDevice,
  vehicleReturnDevice,
  syncRegisterDevice
} from '@/api/iot/cameraDevice'
import CreateForm from './modules/CreateForm'

export default {
  name: 'CameraDevice',
  components: {
    CreateForm
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        deviceName: null,
        deviceSecret: null,
        iotId: null,
        applyId: null,
        registerStatus: '1',
        gmslCameraTypes: null,
        vehicleId: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '主键ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备名称',
          dataIndex: 'deviceName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备密钥',
          dataIndex: 'deviceSecret',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备ID',
          dataIndex: 'iotId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申请批次ID',
          dataIndex: 'applyId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '注册状态',
          dataIndex: 'registerStatus',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return record.registerStatus === 1 ? '注册成功' : '注册失败'
          }
        },
        {
          title: 'gmsl摄像头类型定义',
          dataIndex: 'gmslCameraTypes',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return this.gmslMap[record.gmslCameraTypes]
          }
        },
        {
          title: '内部车辆ID',
          dataIndex: 'vehicleId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '启用状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return this.statusMap[record.status]
          }
        },
        {
          title: '绑定状态',
          dataIndex: 'bindStatus',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            return record.bindStatus === 1 ? '已绑定' : '未绑定'
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      gmslMap: {
        0: 'CAMERA_FRONT_LONG-前',
        1: 'CAMERA_FRONT_SHORT-前',
        2: 'CAMERA_FRONT_WIDE-前',
        3: 'CAMERA_FRONT_FISH-前',
        4: 'CAMERA_LEFT_LONG-左',
        5: 'CAMERA_LEFT_SHORT-左',
        6: 'CAMERA_LEFT_WIDE-左',
        7: 'CAMERA_LEFT_FISH-左',
        8: 'CAMERA_RIGHT_LONG-右',
        9: 'CAMERA_RIGHT_SHORT-右',
        10: 'CAMERA_RIGHT_WIDE-右',
        11: 'CAMERA_RIGHT_FISH-右',
        12: 'CAMERA_BACK_LONG-后',
        13: 'CAMERA_BACK_SHORT-后',
        14: 'CAMERA_BACK_WIDE-后',
        15: 'CAMERA_BACK_FISH-后'
      },
      statusMap: {
        0: '已启用',
        1: '已禁用',
        2: '已删除'
      },
      statusOptions: [
        {
          label: '启用',
          value: '0'
        },
        {
          label: '禁用',
          value: '1'
        },
        {
          label: '删除',
          value: '2'
        }
      ],
      registerStatusOptions: [
        {
          label: '注册成功',
          value: '1'
        },
        {
          label: '注册失败',
          value: '2'
        }
      ],
      bindStatusOptions: [
        {
          label: '未绑定',
          value: '0'
        },
        {
          label: '已绑定',
          value: '1'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询阿里云音视频摄像头设备列表 */
    getList() {
      this.loading = true
      listCameraDevice(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    syncRegisterDevice() {
      this.syncLoading = true
      syncRegisterDevice()
        .then((res) => {
          this.syncLoading = false
          this.$message.success(res.msg, 3)
        })
        .catch(() => {
          this.syncLoading = false
        })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        deviceName: undefined,
        deviceSecret: undefined,
        iotId: undefined,
        applyId: undefined,
        registerStatus: '1',
        gmslCameraTypes: undefined,
        vehicleId: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delCameraDevice(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    webReturn(record) {
      webReturnDevice({ id: record.id }).then((res) => {
        this.$message.success(res.msg, 3)
        this.getList()
      })
    },
    vehicleReturn(record) {
      vehicleReturnDevice({ id: record.id }).then((res) => {
        this.$message.success(res.msg, 3)
        this.getList()
      })
    }
  }
}
</script>
